.NavBar {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: var(--navOffset);
  align-items: center;
  background-color: white;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: var(--background-white);
}

.NavLink {
  height: 100%;
  display: flex;
  align-items: center;
  vertical-align: center;
  font-weight: bold;
  font-size: 18px;
  color: #3c339a;
}

.NavLink > .active {
  color: #5fb7cf;
}

.NavLink:hover {
  color: #aee4ed;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.5s;
  transition: color 0.5s;
}

.link {
  display: block;
  padding: 30px 30px;
}
