.social-link-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  vertical-align: center;
  gap: 10px;
  font-size: 25px;
  background-color: black;
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 8px;
  color: white;
}

.social-link-container:hover {
  background-color: rgb(100, 100, 100);
  transition: background-color 0.5s;
}
