.Home {
  height: var(--availScreen);
  background-color: var(--background-white);
  display: flex;
  flex-direction: column;
  font-size: 50px;
  padding-top: 18vh;
  align-items: center;
  gap: 12px;
}

.headshot {
  width: 300px;
  border-radius: 50%;
  box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.5);
}

.social-bar {
  display: flex;
  flex-direction: row;
  gap: 16px;
}
