.About {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 32px;
  gap: 40px;
}

h2 {
  color: blue;
}

.Resume {
  align-self: center;
  text-align: center;
  width: 80%;
  height: 100%;
  border: 2px solid black;
  border-radius: 20px;
  transition: background-color 0.5s;
}

.Resume:hover {
  background-color: rgb(176, 228, 255);
  transition: background-color 0.5s;
}
